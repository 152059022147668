<template>
  <section class="report">
    <div class="pa-3">
      <h1 class="page-title">Бронирования для пространств</h1>
    </div>
    <reports-filter
      class="mb-5"
      v-model="filterData"
      @submitFilter="handleSubmitFilter"
      :imageUrl="imageFile"
      refs="reports"
      @getFile="downloadImage"
    />

    <v-container>
      <data-graph ref="graph" :chartData="chartData" :options="options" />
    </v-container>
  </section>
</template>

<script>
import ReportsFilter from "@/components/ReportsFilter.vue";
import DataGraph from "@/components/ReportPage/DataGraph.vue";

export default {
  name: "BookingForSpace",
  components: { ReportsFilter, DataGraph },
  data: () => ({
    filterData: {
      space: null,
      interval: [
        `${new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)}`,
        `${new Date(
          Date.now() +
            24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)}`,
      ],
    },
    imageFile: null,
    options: {
      responsive: true,
      // maintainAspectRatio: false,
    },
  }),
  mounted() {
    this.$store.dispatch("fetchSpaces");
    this.$store.commit("SET_REPORT_TABLE", []);
  },
  computed: {
    chartData() {
      return {
        labels: this.listDates,
        datasets: [
          {
            label: "Бронирований за день, ч",
            data: this.listBooking,
            backgroundColor: "#377BB5",
          },
          {
            label: "Отмененные бронирования, ч",
            data: this.listCanceledBooking,
            backgroundColor: "#EEAC57",
          },
        ],
      };
    },
    listCanceledBooking() {
      return this.$store.getters.getReportTable.map(
        (item) => item.total_cancelled_hours
      );
    },
    listBooking() {
      console.log("data = ", this.$store.getters.getReportTable);
      return this.$store.getters.getReportTable.map(
        (item) => item.total_active_hours
      );
    },
    listDates() {
      return this.$store.getters.getReportTable.map((item) => item.date);
    },
  },
  methods: {
    handleSubmitFilter() {
      let payload = {};
      payload.space_name = this.filterData.space.name;
      payload.interval = {
        begin: this.filterData.interval[0],
        end: this.filterData.interval[1],
      };
      payload.location = this.filterData.space.location;
      this.imageFile = null;
      this.$store.dispatch("fetchBookingForSpace", payload).then(() => {});
    },
    downloadChart() {},
    downloadImage() {
      this.$refs.graph.$refs.chart.$refs.canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "123");
        link.click();
      });
    },
  },
  watch: {
    dates: {
      handler(val) {
        console.log("dates = ", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
